<template>
  <div>
    <b-col
      md="12"
      class="form-group"
      v-if="
        field.industrialStructParameterType === 'select_unique' ||
        field.industrialStructParameterType === 'select_multiple'
      "
    >
      <label :for="`custom-field-${field.id}`">
        {{ field.label }}
      </label>
      <SingleSelect
        :id="`custom-field-${field.id}`"
        :placeholder="$t('Select')"
        :optionSelected="verifySelectedOptions()"
        :options="
          field.industrialStructParameterOption.map((item) => ({
            value: item.id,
            text: item.name
          }))
        "
        :searchable="false"
        :translate="true"
        :state="field.required && hasError ? verifyValidFields(field) : null"
        :multiselect="field.industrialStructParameterType === 'select_multiple' ? true : false"
        :class="field.industrialStructParameterType === 'select_multiple' ? 'custom-multiple' : ''"
        @input="(value) => alterValueInternal(value)"
      >
        {{ $t('RequiredField') }}
      </SingleSelect>
    </b-col>
    <b-col
      md="12"
      class="form-group input-block"
      v-if="
        field.industrialStructParameterType === 'input_text' ||
        field.industrialStructParameterType === 'input_numeric'
      "
    >
      <b-form-group
        :label="field.label"
        :label-for="`custom-field-${field.id}`"
        :invalid-feedback="$t('RequiredField')"
      >
        <b-form-input
          :id="`custom-field-${field.id}`"
          :value="fieldValueInternal"
          :placeholder="$t('ResourceStatus.Placeholder')"
          :state="field.required && hasError ? verifyValidFields(field) : null"
          :type="field.industrialStructParameterType === 'input_numeric' ? 'number' : 'text'"
          @input.native="
            (e) => {
              let newValue = applyMask(e.target.value);
              e.target.value = newValue;
              alterValueInternal(newValue);
            }
          "
        />
      </b-form-group>
    </b-col>
    <b-col
      md="12"
      class="form-group input-text-area"
      v-if="field.industrialStructParameterType === 'input_text_area'"
    >
      <b-form-group
        :label="field.label"
        :label-for="`custom-field-${field.id}`"
        :invalid-feedback="$t('RequiredField')"
      >
        <b-form-textarea
          :id="`custom-field-${field.id}`"
          :value="fieldValueInternal"
          :placeholder="$t('ResourceStatus.Placeholder')"
          :state="field.required && hasError ? verifyValidFields(field) : null"
          @input="(value) => { alterValueInternal(value)}"
          rows="2"
          no-resize
        />
      </b-form-group>
    </b-col>
    <b-col
      md="12"
      class="custom-switch-button"
      v-if="field.industrialStructParameterType === 'switch'"
    >
      <div class="d-flex align-items-center">
        <b-form-checkbox
          :id="field.name"
          v-model="field.value"
          name="resource-check-button"
          switch
          class="check-button p-0"
          @change="(value) => alterValueInternal(value)"
        />
        <div class="text-allow">{{ $t(field.name) }}</div>
      </div>
    </b-col>
    <b-col
      md="12"
      class="form-group datepicker"
      v-if="field.industrialStructParameterType === 'datepicker' || field.industrialStructParameterType === 'range_dates' || field.industrialStructParameterType === 'input_hour'"
    >
    <b-form-group
        :label="field.label"
        :label-for="`custom-field-${field.id}`"
        :invalid-feedback="$t('RequiredField')"
      >
        <DateTimePicker
            :datepicker-id="`${field.id}`"
            v-model="datepicker"
            position="bottom"
            :range="field.industrialStructParameterType === 'range_dates'"
            :type="field.industrialStructParameterType === 'input_hour' && 'time'"
            :disabled="false"
            ref="dateTimePickerRef"
            :weekDays="setWeekDays()"
            :month-names="setMonths()"
            :block-dates="blockDates"
            :txt-cancel-button="$t('Datepicker.Cancel')"
            :txt-save-button="$t('Datepicker.Save')"
            :txt-time="$t('Datepicker.Time')"
            :txt-date="$t('Datepicker.Date')"
            :maxRangeDates="maxRangeDates"
            @input="(value) => handleDatepickerChange(value)"
          >
          <template v-slot:input>
            <div class="input-with-icon" >
              
                <TimeIcon class="icon-input" v-if="field.industrialStructParameterType === 'input_hour'"/>
                <CalendarIcon class="icon-input" v-if="field.industrialStructParameterType === 'datepicker'"/>

                <b-form-input
                  :class="[
                    field.industrialStructParameterType !== 'range_dates' && 'input-datepicker'
                  ]"
                  :id="`custom-field-${field.id}`"
                  :value="inputDatepicker"
                  type="text"
                  :placeholder="field.industrialStructParameterType === 'range_dates' 
                    ? `00-00-0000 ${$t('Operator.Until')} 00-00-0000` 
                    :field.industrialStructParameterType === 'datepicker' ? '00-00-0000' 
                    : '00:00'"

                  onkeydown="return false;"
                />
              </div>
          </template>
        </DateTimePicker>
      </b-form-group>
    </b-col>
  </div>
</template>

<script>
import DateTimePicker from 'components-code/date-time-picker/DatePickerFull.vue'
import { BFormGroup, BFormInput, BButton, BCol, BFormCheckbox, BFormTextarea } from 'bootstrap-vue';
import moment from "moment";

  export default {
    name: 'custom-fields',
    components: {
      SingleSelect: () => import('@core/components/multiselect/SingleSelect.vue'),
      CalendarIcon: () => import('@core/assets/icons/calendar-icon.svg'),
      TimeIcon: () => import('@core/assets/icons/time-icon.svg'),
      BFormGroup,
      BFormInput,
      BButton,
      BCol,
      BFormCheckbox,
      BFormTextarea,
      DateTimePicker,
    },
    props: {
      field: {
        type: Object,
        default: {}
      },
      hasError: {
        type: Boolean,
        default: null
      }
    },
    data() {
      return {
        data: {},
        fieldValueInternal: null,
        datepicker:[],
        inputDatepicker: null
      };
    },
    computed: {
      maxRangeDates() {
        return 7;
      },
      defaultDate() {
        //Periodo de dias pré selecionados para o range date
        return [
          new Date(new Date().setDate(new Date().getDate() - (this.maxRangeDates - 1))),
          new Date()
        ];
      }
    },
    created() {
      if(this.field.industrialStructParameterType === 'range_dates'){
        this.datepicker =[...this.defaultDate];
      }
      else{
        this.datepicker = new Date()
      }
    },
    mounted() {
      this.fieldValueInternal = this.field.value;
    },
    methods: {
      handleDatepickerChange(value) {
        this.datepicker = value

        this.inputDatepicker = this.field.industrialStructParameterType === 'range_dates' 
        ? `${moment(value[0]).format("DD/MM/YYYY")} ${this.$t('Operator.Until')} ${moment(value[1]).format("DD/MM/YYYY")}`
        : moment(value).format("DD/MM/YYYY");
    },
      resetDatepicker() {
        this.datepicker = [...this.defaultDate];
      },
      
    setWeekDays() {
      const weekdays = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];

      return weekdays.map((day) => ({
        name: this.$t(`Datepicker.Weekdays.Full.${day}`),
        short: this.$t(`Datepicker.Weekdays.Short.${day}`),
      }));
    },
    setMonths() {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      return months.map((month) => ({
        name: this.$t(`Datepicker.Months.Full.${month}`),
        short: this.$t(`Datepicker.Months.Short.${month}`),
      }));
    },
    blockDates(date) {
      const currentDate = moment(moment().format("YYYY-MM-DD"));
      const inspected = moment(moment(new Date(date)).format("YYYY-MM-DD"));
      return inspected > currentDate;
    },
      applyMask(value) {
        if (!this.field.mask) return value;

        return value.replace(this.field.mask, '');
      },
      verifyValidFields(field) {
        return field.value == null ||
          field.value.length == 0 ||
          field.value == '' ||
          field.value == 0
          ? false
          : true;
      },
      alterValueInternal(value) {
        let valueInternal = value;

        if (this.field.industrialStructParameterType === 'select_multiple') {
          valueInternal = valueInternal.map((e) => e.value);
        }

        if (this.field.industrialStructParameterType === 'select_unique') {
          valueInternal = valueInternal && valueInternal.value;
        }

        this.fieldValueInternal = valueInternal;

        this.$emit('input', this.fieldValueInternal);
      },
      verifySelectedOptions() {
        if (
          this.fieldValueInternal === null ||
          this.field.industrialStructParameterOption.length === 0
        )
          return null;

        const isMultipleSelect = this.field.industrialStructParameterType === 'select_multiple';
        const fieldValueInternalSelecteds = isMultipleSelect
          ? this.fieldValueInternal
          : [Number(this.fieldValueInternal)];
        const optionsMultiple = this.field.industrialStructParameterOption
          .filter((e) => fieldValueInternalSelecteds.includes(e.id))
          .map((e) => ({
            value: e.id,
            text: e.name
          }));

        return isMultipleSelect ? optionsMultiple : optionsMultiple[0];
      }
    }
  };
</script>
<style lang="scss">

  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 0 5px 5px 0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #cfc4be;
    border-radius: 9px;
  }

  .input-with-icon {
    position: relative;
  }

  .input-with-icon .icon-input {
    position: absolute;
    left: 14px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }

  .input-with-icon .input-datepicker {
    padding: 9px 14px 9px 42px;
  }
  
  .input-block {
    padding-inline: 16px !important;
  }

  .custom-multiple {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100% !important;
    border: none;

    .multiselect-invalid-feedback {
      margin: 3.5px 0 0 5px;
    }

    .multiselect-component-single {
      .multiselect__tags {
        width: 100% !important;
        height: 100% !important;
        white-space: nowrap !important;
        display: flex !important;
        align-items: center !important;
        overflow-x: scroll;
        overflow-y: hidden;
        margin-top: 0;
        height: 100% !important;
        cursor: grabbing;
        cursor: -webkit-grabbing;

        .multiselect__placeholder {
          padding-bottom: 8px;
          color: #cfc4be;
        }

        .multiselect__tag {
          background-color: #974900;
          margin-right: 0.6rem;
          font-size: 13px;

          .multiselect__tag-icon {
            line-height: 21px;

            &:hover {
              background-color: #974900;
              text-decoration: none;
              opacity: 0.75;

              &:after {
                color: #000;
              }
            }

            &:after {
              color: #fff;
            }
          }
        }
        &::-webkit-scrollbar {
          display: none !important;
        }
      }

      .multiselect__select {
        height: 34px;
        margin-top: 2px;
        background-color: #fff;
        z-index: 1;
      }
    }

    &.focus {
      box-shadow: none;
    }
  }

  .custom-control-label:after {
    top: 3px !important;
  }

  .check-button {
    padding-block: 8px;
    line-height: 20px;
    min-height: 20px;
  }

  .text-allow {
    color: #4c4541;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  .custom-switch-button {
    .custom-control-label {
      margin: 0;
      line-height: 20px;
    }

    .custom-control-input ~ .custom-control-label::before {
      background-color: #e2e2e2;
      height: 20px;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      background-color: #974900;
    }

    .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
      background-color: #e2e2e2;
      border-color: #e2e2e2;
    }
  }
</style>
